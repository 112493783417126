.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.055);
  }
  
  .modal-main {
    padding: 12px 24px; /* Some padding */
    position:fixed;
    background: white;
    width: auto;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }

.modal Button {
    background-color: rgb(238, 65, 65); /* Green background */
    border: 3px solid skyblue; /* Green border */
    color: white; /* White text */
    padding: 10px 24px; /* Some padding */
    cursor: pointer; /* Pointer/hand icon */
    width: auto; /* Set a width if needed */
    height: auto;
    display: inline;
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }